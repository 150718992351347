(function () {
    'use strict';

    angular.module('modules.page_fix.pages').component('pageFixSummaryChangeTypes', {
        templateUrl: 'app/modules/page-fix/pages/summary/change-types/change-types.html',
        controller: PFSummaryChangeTypes,
        controllerAs: 'vm',
        bindings: {
            spellingFixes: '<',
            links: '<',
            accessibilitySourceCodes: '<',
            accessibilityChecks: '<',
            customFixes: '<',
        },
    });

    PFSummaryChangeTypes.$inject = ['gettextCatalog', 'ng2ActiveFeatureService'];
    /* @ngInject */
    function PFSummaryChangeTypes (gettextCatalog, ng2ActiveFeatureService) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            console.debug(`🚀 ---------------------------------------------------------------🚀`);
            console.debug(`🚀 | file: change-types.component.js:106 | activate | vm:`, vm);
            console.debug(`🚀 ---------------------------------------------------------------🚀`);
            var canAccessAccessibilityCheck = ng2ActiveFeatureService.isFeatureActive('page_fix_accessibility_check');
            var canAccessAccessibilitySourceCode = ng2ActiveFeatureService.isFeatureActive(
                'page_fix_accessibility_source_code',
            );
            var canAccessQALinks = ng2ActiveFeatureService.isFeatureActive('page_fix_qa_links');
            var canAccessQAMisspellings = ng2ActiveFeatureService.isFeatureActive('page_fix_qa_misspelling');
            var canAccessCustom = ng2ActiveFeatureService.isFeatureActive('page_fix_qa_custom');

            vm.chartLabels = [];
            vm.chartSeries = [''];
            vm.barColors = [];
            vm.chartData = [[]];

            if (canAccessQAMisspellings) {
                vm.chartLabels.push(gettextCatalog.getString('Spelling fixes'));
                vm.barColors.push('#a8d6f3');
                vm.chartData[0].push(vm.spellingFixes);
            }

            if (canAccessQALinks) {
                vm.chartLabels.push(gettextCatalog.getString('Link fixes'));
                vm.barColors.push('#a8d6f3');
                vm.chartData[0].push(vm.links);
            }

            if (canAccessAccessibilityCheck || canAccessAccessibilitySourceCode) {
                vm.chartLabels.push(gettextCatalog.getString('Accessibility fixes'));
                vm.barColors.push('#a8d6f3');
                vm.chartData[0].push(vm.accessibilityChecks + vm.accessibilitySourceCodes);
            }

            if (canAccessCustom) {
                vm.chartLabels.push(gettextCatalog.getString('Custom fixes'));
                vm.barColors.push('#a8d6f3');
                vm.chartData[0].push(vm.customFixes);
            }

            var max = vm.chartData[0].reduce(function (a, b) {
                return Math.max(a, b);
            }, 0);

            vm.chartOptions = {
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-0',
                            ticks: {
                                min: 0,
                                max: max * 1.5,
                            },
                            display: false,
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            barPercentage: 1,
                            categoryPercentage: 0.5,
                            gridLines: {
                                display: false,
                                drawBorder: false,
                            },
                            ticks: {
                                fontColor: '#a6a6a6',
                                fontSize: 11,
                            },
                        },
                    ],
                },
                drawLabelOnChart: true,
            };

            vm.chartOverrides = [
                {
                    borderWidth: 0,
                    backgroundColor: 'rgba(54, 162, 235, 0.5)',
                },
            ];
        }
    }
})();
