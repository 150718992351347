import { PageFixModelHelper } from '@client/app/modules/page-fix/services/model-helper/page-fix-model-helper';

(function () {
    'use strict';

    angular.module('models').factory('AccessibilityFastTrackModel', AccessibilityFastTrackModel);

    AccessibilityFastTrackModel.$inject = [];
    function AccessibilityFastTrackModel () {
        function Model (attributes) {
            angular.extend(this, attributes);
        }

        Model.prototype.getPageFixSelector = getPageFixSelector;

        return Model;

        // /////////

        function getPageFixSelector () {
            return 'q;' + PageFixModelHelper.getSelectorValue(this.source_code);
        }
    }
})();
