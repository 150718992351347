import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { LoaderModule } from '@monsido/core/loader/loader.module';
import { RollbarService } from '../ng2/external/rollbar/rollbar-error-handler.service';
import { LoadingModule } from '@monsido/services/loading/loading.module';
import { MonEventModule } from '@monsido/services/mon-event/mon-event.module';
import { MonScoreComponentsModule } from './modules/score/components/mon-score-components.module';
import { MonScoreServicesModule } from './modules/score/services/mon-score-services.module';
import { MonBrowserModule } from '@monsido/services/mon-browser/mon-browser.module';
import { MonLegacyBrowserModule } from '@monsido/modules/legacy-browsers/legacy-browser.module';
import { MonCookieBannerModule } from '@monsido/modules/cookie-banner/cookie-banner.module';
import { RollbarErrorHandlerService, rollbarFactory } from '../ng2/external/rollbar/rollbar-error-handler.service';
import { OverlayService } from '@monsido/services/overlay-service/overlay.service';
import { AccessibilityComponentsModule } from './modules/accessibility/components/accessibility-components.module';
import { MonIssueModule } from '@monsido/modules/issue/issue.module';
import { QaModule } from './modules/qa/qa.module';
import { PageDetailsModule } from '@monsido/modules/page-details/page-details.module';
import { DataPrivacyModule } from '@monsido/modules/data-privacy/data-privacy.module';
import { MonLabelsModule } from '@monsido/modules/labels/labels.module';
import { FormsModule } from '@monsido/forms/forms.module';
import { ActiveFeatureModule } from '@monsido/services/active-feature/active-feature.module';
import { OverlayWrapperModule } from './modules/overlay-wrapper/overlay-wrapper.module';
import { DirectNavigationService } from '@monsido/services/direct-navigation-service/direct-navigation.service';
import { CustomerHasPlanPipe } from 'ng2/shared/pipes/customer-has-plan/customer-has-plan.pipe';
import { StatisticsModule } from '@monsido/modules/statistics/statistics.module';
import { IsStatsDemoDataPipe } from '@monsido/modules/statistics/pipes/is-stats-demo-data/is-stats-demo-data.pipe';
import { ReportCenterModule } from '@monsido/modules/report-center/report-center.module';
import { RolePipe } from '@monsido/core/pipes/role/role.pipe';
import { MonInventoryModule } from '@monsido/ng2/modules/inventory/inventory.module';
import { UIRouterModule } from '@uirouter/angular';
import { ADMIN_API_USERS_STATES } from './pages/admin/api-users/api-users.route';
import { ADVANCED_ANALYTICS_STATES } from './pages/advanced-analytics/advanced-analytics.route';

@NgModule({
    declarations: [],
    imports: [
        LoaderModule,
        LoadingModule,
        BrowserModule,
        CommonModule,
        UIRouterUpgradeModule.forRoot(),
        UIRouterModule.forChild({ states: [
            ...ADMIN_API_USERS_STATES,
            ...ADVANCED_ANALYTICS_STATES,
        ] }),
        UpgradeModule,
        FormsModule,
        MonEventModule,
        MonScoreComponentsModule,
        MonScoreServicesModule,
        MonBrowserModule,
        MonLegacyBrowserModule,
        MonCookieBannerModule,
        QaModule,
        AccessibilityComponentsModule,
        MonIssueModule,
        MonInventoryModule,
        PageDetailsModule,
        DataPrivacyModule,
        MonLabelsModule,
        OverlayWrapperModule,
        ReportCenterModule,
        StatisticsModule,
        ActiveFeatureModule,
    ],
    providers: [
        RollbarErrorHandlerService,
        OverlayService,
        DirectNavigationService,
        { provide: ErrorHandler, useClass: RollbarErrorHandlerService },
        { provide: RollbarService, useFactory: rollbarFactory },
        CustomerHasPlanPipe,
        IsStatsDemoDataPipe,
        RolePipe,
    ],
})
export class AppModule {
    constructor (private upgrade: UpgradeModule) {}

    ngDoBootstrap (): void {
        this.upgrade.bootstrap(document.body, ['monsido'], { strictDi: true });
    }
}
